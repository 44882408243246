// @mixin appGuest {
@import "./app.scss";
$color-primary: #ea234f;

body {
    font-family: Arial, "sans-serif";
    color: #555;
    margin: 0;
    padding: 0;
    color: #595959;
    line-height: 22px;
}

html,
body {
    height: 100%;
}

a,
button {
    text-decoration: none;
    color: #4472e2;
    cursor: pointer;
}

:focus {
    outline: none;
    box-shadow: none !important;
    border-color: #aaa !important;
}

ul,
li {
    list-style-type: none;
}

div,
input {
    box-sizing: border-box;
}

.signup-box h2 {
    font-size: 40px;
    margin-bottom: 5px;
    font-weight: 700;
    color: #222;
    text-align: center;
    line-height: 40px;
}

.signup-box h2 span {
    display: block;
    color: #4472e2;
    margin-bottom: 10px;
}

.signup-box h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: normal;
    color: #a4a4a4;
    text-align: center;
}


.logomain {
    position: absolute;
    top: 30px;
    left: 30px;
}

.signup-box .sublabel {
    font-size: 20px;
    margin-bottom: 21px;
    font-weight: 700;
    color: #606060;
}

.titlesmall {
    font-size: 26px;
}

.mt0 {
    margin-top: 0 !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}


.font-sm {
    font-size: 15px;
}


.btn-large {
    color: #fff;
    background: $color-primary;
    padding: 10px 20px;
    font-size: 26px;
    border-radius: 20px;
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 24px;
    border: 2px solid $color-primary;
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;
}

.btn-sm {
    color: #FFFFFF;
    background: $color-primary;
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;
    border: 2px solid $color-primary;
    padding: 7px 20px;
    font-size: 16px;
    border-radius: 20px;
}

.btn-sm:disabled,
.btn-sm:disabled:hover {
    color: #FFFFFF !important;
    background: #b3afaf;
    transition: background-color 0.5s, color 0.5s, border-color 0.5s;
    border: 2px solid #b3afaf;
    padding: 7px 20px;
    font-size: 16px;
    border-radius: 20px;
}

.btn-sm:hover,
.btn-large:hover {
    background-color: #FFFFFF;
    color: $color-primary !important;
    border-color: $color-primary;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.loginsignup-bg {
    width: 100vw;
    min-height: 100vh;
    background: url("../images/login-signup-bg.jpg") no-repeat;
    background-size: cover !important;
    padding: 1em 0em;
}

.closeicon {
    position: absolute;
    top: 15px;
    right: 30px;
    color: #bfbfbf;
    font-size: 36px;
}

.closeicon:hover {
    color: #999;
}

.resettext {
    color: #5a5a5a;
    line-height: 30px;
}

.resettext span {
    color: #a4a4a4;
    display: block;
    font-size: 15px;
}


.formrow {
    // margin-bottom: 24px;
}

.signup-box {
    background: #fff;
    position: absolute;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 15%;
    padding: 30px 50px 30px 50px;
    width: 460px;
    border-radius: 30px;
    max-height: 98%;
    overflow: auto;
}

.form-control-custom {
    margin-bottom: 15px;
    position: relative;
    display: block;
    width: 100%;
    float: left;
}

.form-control-custom label {
    display: block;
    color: #595959;
}

.form-control-custom input {
    border: none;
    border-bottom: solid 1px #dadada;
    padding: 0px;
    width: 100%;
    font-size: 18px;
}

.search-countries {
    border: solid 1px #dadada !important;
    padding: 10px !important;
}

.form-control-custom .inputinfo {
    position: absolute;
    right: 0;
    top: 26px;
}


.rowcol {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
}

.col-50 {
    width: calc(50% - 30px);
    margin: 0 15px;
    float: left;
}

.termsagree input {
    float: left;
    width: auto;
    height: 32px;
    width: 32px;
}

.termsagree span {
    display: block;
    margin-left: 16px;
}


.sep-option {
    border-bottom: solid 1px #dadada;
    text-align: center;
    color: #acacac;
    font-weight: bold;
    margin: 20px 0 20px 0;
    display: block;
}

.sep-option span {
    display: inline-block;
    background: #fff;
    padding: 0 20px;
    position: relative;
    top: 10px;
    font-size: 18px;
}

.pw-fields input {
    padding: 10px 0;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    border: solid 1px #dadada;
    width: calc(15% - 4px);
    float: left;
    border-radius: 5px;
    margin: 10px 5px;
}

.alertmsg {
    font-size: 13px;
    color: #ff3b3b;
}

.artistname {
    position: absolute;
    bottom: 20px;
    left: 30px;
    font-weight: bold;
}

.artistname a {
    color: #fff;
}

.artistname span, .artistname a:hover {
    color: #ea2050;
}

@media (max-width: 991px) {
    .signup-box {
        right: 5%;
    }

    .google-recaptcha iframe {
        // width: 90% !important;
        margin: 0px !important;
    }

    .refer-earn .thumbs-re .thumb:last-child img {
        width: 100% !important;
    }

    section.refer-earn .thumbs-re .thumb {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media (max-width: 768px) {
    body.help-content-modual{
        overflow: unset !important;
    }
    html,
    body {
        overflow: auto;
        font-size: 14px;
    }

    .logomain {
        position: static;
        padding: 30px;
    }

    .signup-box {
        transform: translateY(0);
        right: 5%;
        margin: 15px;
        top: 100px;
        padding: 25px;
        max-height: 80%;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
    }

    section.create-submit-earn .thumbs-cse {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 600px) {
    h2 {
        font-size: 32px;
    }

    .sublabel {
        font-size: 18px;
    }

    .signup-box {
        position: static;
        transform: translateY(0);
        margin: 15px;
        width: calc(100% - 30px);
        max-height: none;
        margin-top: 0;
        padding: 25px
    }

    .signup-box h3 {
        font-size: 16px;
    }

    .artistname {
        padding: 0.25em 0.5em;
        position: relative;
        margin: 2em 0em;
        background: rgba(0, 0, 0, 0.25);
        display: inline-block;
        border-radius: 0.5em;
    }

    // .artistname a {
    // color: rgba(0, 0, 0, 0.5);
    // }

    .pw-fields input {
        font-size: 18px;
        line-height: 25px;
        margin: 5px 4px;
    }

    footer.page-footer .mcontainer div.content-copyright ul {
        width: 271px !important;
    }

    section.tending-images .image-grid li img {
        width: 300px;
        height: 300px;
    }
}

// }

.social-login-button .register-social-button {
    // display: inline-grid !important;
    color: inherit;
    text-decoration: none;
    align-items: center;
    display: inline-grid;
}

.social-login-button {
    display: flex;
    /* flex-direction: row; */
    /* flex-wrap: nowrap; */
    justify-content: flex-start;
    align-items: stretch;
}

.signup-box::-webkit-scrollbar,
.country-dropdown::-webkit-scrollbar {
    display: none;
}

.social-login-button a img {
    width: 45px;
    height: 45px;
}

// .tags-dropdown.keyword-suggetion {
//     background: red;
//     height: 350px;
//     overflow: scroll;
// }

.signup-box .login-page-sign-in {
    color: #606060;
}

#rc-anchor-container .rc-anchor-normal .rc-anchor-pt {
    // right: 36px !important;
    // width: 25% !important;
    display: none !important;
}

@media (max-width: 350px) {
    #rc-anchor-container {
        overflow: hidden;
        width: 75% !important;
    }
}

.google-recaptcha iframe {
    overflow: hidden;
    width: 100%;
    margin-left: 15px;
    // width: 348px;
    height: 95px;
}