.country {
    fill: #808389;
    stroke: #808389;
    stroke-width: 1px;
}

.hovered {
    fill: #808389;
}

.background {
    fill: #808389;
    pointer-events: all;
}

.active {
    fill: orange;
}

.meteor {
    opacity: 1;
    stroke: #fff;
    stroke-width: 0.5;
}

.tool-tip {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: absolute;
    display: none;
    width: auto;
    height: auto;
    background: none repeat scroll 0 0 #fff;
    border: 0 none;
    border-radius: 8px 8px 8px 8px;
    box-shadow: -3px 3px 15px #888888;
    color: #000;
    font: 12px sans-serif;
    padding: 5px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    opacity: 0;
    max-width: 150px;
}



.header {
    position: sticky;
    top: 0px;
}

tr th.header {
    padding: 13px;
    background: #f7f7f7;
    color: #000;
}