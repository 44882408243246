.ic{
    display: inline-block;
}

.ic.icon-globe
{
    background: url(./ic-globe.svg) no-repeat;
    width: 24px;
    height: 24px;
}

.ic.icon-dd
{
    background: url(./ic-dd.svg) no-repeat;
    width: 16px;
    height: 10px;
}