
.otpContainer {
    margin: 5% auto;
  }

.otpInput {
    width: 3rem !important;
    height: 3rem;
    margin: 0 1rem;
    font-size: 2rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  /* Chrome, Safari, Edge, Opera */
  .otpInput::-webkit-outer-spin-button,
  .otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otpInput[type=number] {
  -moz-appearance: textfield;
}
  /*  Add breakpoint for iPhone */
  @media only screen and (max-width: 375px) {
    .otpInput {
      // width: 1.5rem !important;
      // height: 1.5rem;
      font-size: 1rem;
      padding: 8px;
    }
  }