.help-hero {
    // position: relative;
}

.searched-hint {
    position: absolute;
    background: #FFFFFF;
    width: 43.2%;
    // left: 30%;
    top: 145px;
    box-shadow: 0px 1px 3px 1px #CCCCCC;
    // padding:10px;
    z-index: 1;
    max-height: 500px;
    overflow: auto;
    border-radius: 5px;
}

.searched-hint ul {
    margin: 0;
    padding: 0;
}

.searched-hint ul li {
    padding: 0px 5px;
}